"use client"

import type { FC } from "react"
import {
  Badge as MuiBadge,
  type BadgeProps as MuiBadgeProps,
} from "@mui/material"

export type BadgeProps = MuiBadgeProps

export const Badge: FC<BadgeProps> = (props) => <MuiBadge {...props} />
