import { Avatar as MuiAvatar } from "@/components/ui/data-display/Avatar"

import { useLoadImage } from "@/app/_components/LazyImage/useLoadImage"
import { Badge } from "@/components/ui/data-display/Badge"
import Icon from "@mdi/react"
import type { AvatarProps as MuiAvatarProps } from "@mui/material"
import { useTheme, type Theme } from "@mui/material/styles"

export type AvatarSize = "tiny" | "small" | "medium" | "large"

export interface AvatarProps extends MuiAvatarProps {
  iconPath?: string
  iconColor?: string
  imageRef?: string | null
  imageFit?: "cover" | "contain"
  size?: AvatarSize
  badgeContent?: React.ReactNode
}

const _getAvatarImageSize = (size: AvatarSize, theme: Theme) => {
  switch (size) {
    case "tiny":
      return theme.spacing(2)
    case "small":
      return theme.spacing(3)
    case "medium":
      return theme.spacing(4)
    case "large":
      return theme.spacing(5)
    default:
      return theme.spacing(3)
  }
}

const _getAvatarSizeClass = (size?: AvatarSize) => {
  switch (size) {
    case "tiny":
      return "h-4 w-4"
    case "small":
      return "h-6 w-6"
    case "medium":
      return "h-8 w-8"
    case "large":
      return "h-10 w-10"
    default:
      return ""
  }
}

const _getAvatarIconSizeClass = (size?: AvatarSize) => {
  switch (size) {
    case "tiny":
      return "w-3 h-3"
    case "small":
      return "w-4 h-4"
    case "medium":
      return "w-5 h-5"
    case "large":
      return "w-6 h-6"
    default:
      return ""
  }
}

const _getAvatarIconSize = (size?: AvatarSize) => {
  switch (size) {
    case "tiny":
      return 0.5
    case "small":
      return 0.75
    case "medium":
      return 1
    case "large":
      return 1.25
    default:
      return 1
  }
}

export default function Avatar({
  iconPath,
  iconColor,
  imageRef,
  imageFit,
  size = "small",
  badgeContent,
  children,
  ...attributes
}: AvatarProps) {
  const { src } = useLoadImage(imageRef)
  const theme = useTheme()

  const avatarSizeClass = _getAvatarSizeClass(size)
  const imageSize = _getAvatarImageSize(size, theme)
  const avatarIconSizeClass = _getAvatarIconSizeClass(size)
  const iconSize = _getAvatarIconSize(size)

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={badgeContent}
    >
      <MuiAvatar
        {...attributes}
        className={`${avatarSizeClass} ${
          src ? "bg-transparent" : "bg-stone-200"
        } ${attributes.className}`}
        // only by changing the query parameter, the image will be reloaded on change
        src={src}
        slotProps={{
          img: {
            width: imageSize,
            height: imageSize,
            sx: {
              objectFit: imageFit,
              objectPosition: "center",
            },
            ...(attributes?.slotProps?.img ?? {}),
          },
        }}
        variant={src ? (attributes?.variant ?? "square") : "circular"}
      >
        {!children && iconPath && (
          <Icon
            className={avatarIconSizeClass}
            path={iconPath}
            size={iconSize}
            color={iconColor ?? theme.palette.action.active}
          />
        )}
        {children}
      </MuiAvatar>
    </Badge>
  )
}
