"use client"

import { ListItemText as MuiListItemText } from "@mui/material"
import type { ComponentProps } from "react"

export type ListItemTextProps = ComponentProps<typeof MuiListItemText>

export const ListItemText: typeof MuiListItemText = (props) => (
  <MuiListItemText {...props} />
)
