import Avatar, { type AvatarSize } from "@/app/_components/Avatar"
import { Typography } from "@/components/ui/data-display/Typography"
import type { NullableProps, SchemaPick } from "@/global"
import { formatPersonInitials } from "@/utils/format"
import { mdiAccount } from "@mdi/js"
import type { AvatarProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import type { persons } from "dcp-types"
import { useMemo } from "react"
import colors from "tailwindcss/colors"

export interface PersonAvatarProps extends AvatarProps {
  person?: NullableProps<
    SchemaPick<persons, "first_name" | "last_name" | "avatar_ref", false>
  > | null
  size?: AvatarSize
}

const _getAvatarFontSize = (size?: AvatarSize) => {
  switch (size) {
    case "tiny":
      return 10
    case "small":
      return 12
    case "medium":
      return undefined // use @Mui default
    case "large":
      return 24 // use @Mui default
    default:
      return undefined // use @Mui default
  }
}

const useColorsByInitials = (
  firstName?: string | null,
  lastName?: string | null,
): { color: string; bgColor: string } => {
  const theme = useTheme()
  const shades = [
    "500",
    "600",
    "700",
    "800",
  ] as readonly (keyof typeof colors.neutral)[]

  if (!firstName || !lastName) {
    return {
      bgColor: colors.purple?.["800"] ?? theme.palette.primary.main,
      color: theme.palette.getContrastText(
        colors.purple?.[800] ?? theme.palette.primary.main,
      ),
    }
  }

  const firstInitial = firstName?.charAt(0)?.toUpperCase() ?? "A"
  const lastInitial = lastName?.charAt(0)?.toUpperCase() ?? "A"

  function getTailwindColorCodeByLastInitial(
    lastInitial: string,
  ): keyof typeof colors {
    const allowedColors = Object.keys(colors).filter(
      (color) =>
        ![
          "black",
          "white",
          "inherit",
          "current",
          "transparent",
          "lightBlue",
          "warmGray",
          "trueGray",
          "coolGray",
          "blueGray",
        ].includes(color) &&
        shades.every(
          (shade) => colors?.[color as keyof typeof colors]?.[shade],
        ),
    )
    const index = (lastInitial?.codePointAt(0) ?? 65) - 65

    return allowedColors[index % allowedColors.length] as keyof typeof colors
  }

  function getShadeByFirstInitial(
    firstInitial: string,
  ): keyof typeof colors.neutral {
    const index = (firstInitial?.codePointAt(0) ?? 65) - 65

    return shades[index % shades.length] as keyof typeof colors.neutral
  }

  const bgColorCode = getTailwindColorCodeByLastInitial(lastInitial)
  const bgShadeCode = getShadeByFirstInitial(firstInitial)
  const color: string | undefined = colors?.[bgColorCode]?.[bgShadeCode]

  return {
    bgColor: color ?? theme.palette.primary.main,
    color: theme.palette.getContrastText(color ?? theme.palette.primary.main),
  }
}

export default function PersonAvatar({
  person,
  size = "small",
  ...attributes
}: PersonAvatarProps) {
  const { bgColor, color } = useColorsByInitials(
    person?.first_name,
    person?.last_name,
  )
  const theme = useTheme()
  const avatarFontSize = _getAvatarFontSize(size)

  const isNameMaintained = useMemo(() => {
    return person?.first_name || person?.last_name
  }, [person?.first_name, person?.last_name])

  return (
    <Avatar
      variant="circular"
      iconPath={mdiAccount}
      iconColor={theme.palette.common.white}
      imageRef={person?.avatar_ref}
      imageFit="cover"
      size={size}
      {...attributes}
      style={{
        backgroundColor: bgColor,
        color,
      }}
    >
      {isNameMaintained && (
        <Typography fontSize={avatarFontSize} color="common.white">
          {formatPersonInitials(person)}
        </Typography>
      )}
    </Avatar>
  )
}
