/** new: for backup purposes, we are currently restructuring storage organization */
/**
 *
 * @param tenantId organizationId of the tenant, i.e. law firm
 * @returns
 */
export const getTenantBucketName = (tenantId: string) => {
  return `tenant-${tenantId}`
}

export const getDocumentLayoutResultPath = (
  documentId: string,
): `${StorageFolder}/${string}/analysis.json` => {
  return `${StorageFolder.documents}/${documentId}/analysis.json`
}

export const getEmailHtmlPath = (
  emailId: string,
): `${StorageFolder}/${string}/body.html` => {
  return `${StorageFolder.emails}/${emailId}/body.html`
}

export const getDocumentPath = (documentId: string, storageName: string) => {
  return `${StorageFolder.documents}/${documentId}/${storageName}`
}

export const getTextTemplateTextPath = (
  templateId: string,
  componentId?: string | null,
) => {
  if (componentId) {
    return `${StorageFolder.textTemplates}/${templateId}/components/${componentId}/template.txt`
  }
  return `${StorageFolder.textTemplates}/${templateId}/template.txt`
}

export const getFormPath = (
  formId: string,
): `${StorageFolder}/${string}/form.json` =>
  `${StorageFolder.forms}/${formId}/form.json`

export const getFormSubmissionPath = (
  submissionId: string,
): `${StorageFolder}/${string}/submit.json` =>
  `${StorageFolder.formsubmissions}/${submissionId}/submit.json`

export const getFormSubmissionFilePath = (
  submissionId: string,
  ref: string,
) => {
  return `${StorageFolder.formsubmissions}/${submissionId}/${ref}`
}

/**
 * @deprecated
 * @param organizationId
 * @returns
 */
export const getDocumentsBucketName = (organizationId: string) => {
  return `documents-${organizationId}`
}

export enum StorageFolder {
  emails = "emails",
  signatures = "signatures",
  logos = "logos",
  avatars = "avatars",
  forms = "forms",
  formsubmissions = "formsubmission",
  documents = "documents",
  textTemplates = "texttemplates",
}
