import { getResourceUrlByRef } from "@/app/api/v1/utils/pathGenerators"
import { useMemo } from "react"

export const useLoadImage = (imageRef?: string | null) => {
  const src = useMemo(() => getResourceUrlByRef(imageRef), [imageRef])

  return {
    src,
  }
}
